import axios from 'axios';

// console.log('VUE_APP_API_URL', process.env.VUE_APP_API_URL);
const instance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
});
// baseURL: process.env.VUE_APP_API_URL,
// instance.defaults.headers.common['SOMETHING'] = 'something'

export default instance;
