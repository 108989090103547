<template>
  <div class="home">
    <v-container>
      <v-row class="header">
        <v-col md="6" class="header__logo">
          <img
            class="header__logo__image"
            src="@/assets/images/logo-mediabyte.svg"
            alt="Mediabyte"
          />
          <v-btn class="header__logo__button header__logo__icon">
            <img
              src="@/assets/images/whatsapp-icon.png"
              alt="Whatsapp Mediabyte"
            />
          </v-btn>
        </v-col>
        <v-col md="6" class="header__contact">
          <img
            class="header__contact__hand"
            src="@/assets/images/hand.svg"
            alt="Hola Mediabyte"
          />
          <a
            href="mailto:contacto@mediabyte.com.pe"
            class="header__contact__email"
            >contacto@mediabyte.com.pe</a
          >
          <v-btn class="header__contact__button">
            <img
              src="@/assets/images/whatsapp-mediabyte.png"
              alt="Whatsapp Mediabyte"
            />
            ¡Despeguemos!
          </v-btn>
        </v-col>
      </v-row>

      <v-row class="top">
        <v-col md="6">
          <div class="top__left">
            <h1 class="top__left__title">
              SOFTWARE: ¿Cómo identificar que es un buen momento para
              implementarlo en mi negocio?
            </h1>
            <p class="top__left__description">
              Este reporte gratuito te ayudará a identificar la necesidades de
              tu empresa y te brindará una solución práctica para aumentar la
              productividad y desempeño de tu negocio llevándolo a un siguiente
              nivel
            </p>

            <v-btn class="top__left__download" @click="dialogDownload = true">
              Descarga Aquí tu Free Ebook
            </v-btn>
          </div>
        </v-col>
        <v-col md="6">
          <div class="top__right">
            <img
              src="@/assets/images/mediabyte-banner.svg"
              alt="Mediabyte, Desarrollo de apps móviles"
              class="top__right__banner"
            />
          </div>
        </v-col>
      </v-row>

      <v-row class="benefits">
        <v-col>
          <h2 class="benefits__title">
            Esto aprenderás en el <span>reporte gratuito:</span>
          </h2>
        </v-col>
      </v-row>
      <v-row class="benefits">
        <v-col>
          <div class="benefits__list">
            <div class="benefits__list__item">
              <h3 class="benefits__list__item__title">01.</h3>
              <p class="benefits__list__item__description">
                Analizamos y te ayudamos a definir cómo optimizar los procesos
                de tu empresa.
              </p>
            </div>

            <div class="benefits__list__item">
              <h3 class="benefits__list__item__title">02.</h3>
              <p class="benefits__list__item__description">
                Contarás con la guía y asesoría de un consultor experto
                dispuesto en exclusiva para analizar las variables de tu caso de
                negocios.
              </p>
            </div>
            <div class="benefits__list__item">
              <h3 class="benefits__list__item__title">03.</h3>
              <p class="benefits__list__item__description">
                Obtendrás un informe diagnóstico con las recomendaciones del
                plan estratégico de acción para ser aplicado en tu organización.
              </p>
            </div>
            <div class="benefits__list__item">
              <h3 class="benefits__list__item__title">04.</h3>
              <p class="benefits__list__item__description">
                Conocerás los fundamentos básicos que requerirá tu empresa para
                lograr un exitoso ciclo de gestión con el fin de aumentar la
                producción y eficiencia de la organización.
              </p>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <div class="banner">
            <h3>
              Este reporte gratuito te ayudará a identificar las mejores
              soluciones tecnológicas que existen para incrementar la
              productividad, fidelización y crear experiencias digitales
              innovadoras.
            </h3>
            <div class="banner__call">
              <v-btn
                class="banner__call__button"
                @click="dialogDownload = true"
              >
                Descarga Aquí tu Free Ebook
              </v-btn>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row class="footer">
        <v-col md="6" class="footer__logo">
          <img
            class="footer__logo__image"
            src="@/assets/images/logo-mediabyte.svg"
            alt="Mediabyte"
          />
        </v-col>
        <v-col md="6" class="footer__social">
          <a
            href="https://www.facebook.com/MediabytePeru"
            target="_blank"
            class="footer__social__link"
            >Facebook</a
          >
          <a
            href="https://www.linkedin.com/company/mediabyte-pe/"
            target="_blank"
            class="footer__social__link"
            >Linkedin</a
          >
          <!--a
            href="https://www.linkedin.com/company/mediabyte-pe/"
            target="_blank"
            class="footer__social__link"
            >Instagram</a
          -->
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="dialogDownload" width="460px">
      <v-card>
        <v-card-text>
          <div class="report">
            <v-btn
              icon
              color="black"
              class="report__close"
              @click="dialogDownload = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <div class="report__header">
              <img
                src="@/assets/images/free-report-pdf.svg"
                alt="Reporte Gratuito Mediabyte"
                class="report__header__icon"
              />
              <h2>
                Descarga tu reporte gratuito y aprende a cómo identificar que es
                un buen momento para implementarlo en mi negocio.
              </h2>
            </div>
            <div class="report__form">
              <div>
                <v-text-field
                  label="Nombre y Apellido"
                  placeholder="Nombre y Apellido"
                  outlined
                  v-model="name"
                  :error-messages="nameErrors"
                  @change="$v.name.$touch()"
                  @blur="$v.name.$touch()"
                ></v-text-field>
              </div>
              <div>
                <v-text-field
                  placeholder="Correo Electrónico"
                  outlined
                  v-model="email"
                  :error-messages="emailErrors"
                  @change="$v.email.$touch()"
                  @blur="$v.email.$touch()"
                ></v-text-field>
              </div>
              <div>
                <v-btn
                  text
                  :loading="sending"
                  @click="onSend()"
                  block
                  class="report__download"
                >
                  Descarga Aquí tu Free Ebook
                </v-btn>
              </div>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogThanks" width="560px">
      <v-card>
        <v-card-text>
          <div class="report">
            <v-btn
              icon
              color="black"
              class="report__close"
              @click="dialogThanks = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <div class="report__header">
              <img
                src="@/assets/images/thanks.png"
                alt="Reporte Gratuito Mediabyte"
                class="report__header__thank"
              />
              <h1>¡Felicidades!</h1>
              <h2>
                Tu reporte gratuito fue enviado directamente a tu bandeja de
                correo
              </h2>
            </div>

            <div class="report__form">
              <!--p class="report__form__p1">
                En este reporte te compartimos 12 años de experiencia de
                desarrollo de soluciones tecnológicas Web y App para pequeñas,
                medianas y grandes empresas. Te ayudará a identificar la mejor
                solución para aumentar el rendimiento de tu negocio y llevarlo a
                un siguiente nivel
              </p-->
              <p class="report__form__p2">
                Ahora si estás buscando asesoría personalizada o conseguir a un
                experto que te ayude, separa tu consultoría estratégica sin
                costo con nosotros, donde crearemos un plan a medida para tu
                empresa.
              </p>
              <p class="report__form__p3">
                Recibe más información sobre la consultoría estratégica:
              </p>
              <div>
                <v-btn
                  text
                  @click="dialogThanks = false"
                  block
                  class="report__form__clic"
                >
                  Has clic aquí
                </v-btn>
              </div>
              <div>
                <a
                  href="https://mediabyte.com.pe/"
                  @click="dialogThanks = false"
                  target="_blank"
                  block
                  class="report__form__visit"
                >
                  Visita nuestra Web
                </a>
              </div>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import axios from "@/axios-auth";

const { required, email } = require("vuelidate/lib/validators");

export default {
  name: "Home",
  components: {},
  data() {
    return {
      dialogDownload: false,
      dialogThanks: false,
      name: "",
      email: "",
      sending: false,
    };
  },
  mixins: [validationMixin],
  validations: {
    name: { required },
    email: { required, email },
  },
  methods: {
    onSend() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.sending = true;

        let data = {
          origin: "web",
          name: this.name,
          email: this.email,
          type: 1,
        };

        axios
          .post(`v1/lead`, data)
          .then((response) => {
            console.log(response.data);
            this.sending = false;
            this.dialogDownload = false;
            this.dialogThanks = true;
            window.open(process.env.VUE_APP_EBOOK, "_blank");
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
  },
  computed: {
    nameErrors() {
      const errors = [];
      if (!this.$v.name.$dirty) return errors;
      !this.$v.name.required && errors.push("Es un dato requerido.");
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.required && errors.push("Es un dato requerido.");
      !this.$v.email.email &&
        errors.push("Ingrese un correo electrónico correcto.");
      return errors;
    },
  },
};
</script>
